import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import PageRoutes from "./pages";

function App() {
	return <PageRoutes />;
}

export default App;
